.hero-container {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
}
  
  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.85; 

  }
  
  .hero-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
    background: rgba(23, 29, 31, 0.6); /* Semi-transparent background */

  }

  .hero-contentItem {
    position: absolute;
 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;

  }
  .test{
    color: rgb(168, 136, 199);
  }