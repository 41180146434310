.container {
  align-items: center;

  height: 100%;
  max-height: 600px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 30px;
}

.menu {
  align-items: center;

  width: "auto";
  max-width: 400px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 30px;
}

.timeListItem {
  margin: 10px !important;
  /* background-color: rgb(149, 128, 168,0.5) !important; */
  border-radius: 10px !important;
}
@media (max-width: 600px) {
}
.submitButton {
  background-color: black !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.formContainer {
  padding: 20px;
}
