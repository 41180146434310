body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Poppins;

  /* source :        https://mycolor.space/gradient3?ori=to+right+bottom&hex=%238E6BD1&hex2=%237F82B7&hex3=%233956A8&submit=submit */
  background-image: linear-gradient(
    to right bottom,
    #8e6bd1,
    #8970cd,
    #8575c9,
    #8279c4,
    #807dbf,
    #7b7bbb,
    #7579b7,
    #7077b3,
    #646eb0,
    #5766ae,
    #495eab,
    #3956a8
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buttonItem {
  cursor: pointer;
}
.iti {
  color: green;
  width: 100% !important;
}
.iti input {
  height: 1.4375em;
  padding: 25px;
  width: 100% !important;
  border: 1px gray solid;
  border-radius: 5px;
}
.iti input:focus {
  border-color: gray !important;
}
