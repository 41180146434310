.container {
  align-items: center;

  height: 600px;
  max-height: 100%;
  width: 100%;
  max-width: 80%;
  width: 1100px;
  margin: auto;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  border-radius: 20px;
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  /* padding: 30px; */
}

@media (max-width: 600px) {
  .container {
    background-color: lightgreen;
  }
}
.submitButton {
  background-color: black !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.formContainer {
  margin: auto;
  width: 100%;
}

.loginSide {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background-image: radial-gradient(
    circle,
    #7972e2,
    #6863cc,
    #5754b6,
    #4746a1,
    #36388c,
    #313688,
    #2b3483,
    #26327f,
    #2b3b89,
    #304493,
    #344d9e,
    #3956a8
  );
}
.f {
  color: rgb(56, 211, 162);
}
