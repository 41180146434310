.grid_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  row-gap: 20px; /* Vertical gap between rows */
  column-gap: 20px; /* Horizontal gap between columns */
}

.grid_item {
}

@media (max-width: 600px) {
  .rid_container {
    background-color: lightgreen;
  }
}
.submitButton {
  background-color: black !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.f {
  color: rgb(56, 211, 162);
}
